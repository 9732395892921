// extracted by mini-css-extract-plugin
export const root = "AppBlock-module--root--2Lw-2";
export const LEFT = "AppBlock-module--LEFT--1tmTh";
export const LEFT_INSIDE_CONTAINER = "AppBlock-module--LEFT_INSIDE_CONTAINER--1leiD";
export const RIGHT = "AppBlock-module--RIGHT--HGar0";
export const RIGHT_INSIDE_CONTAINER = "AppBlock-module--RIGHT_INSIDE_CONTAINER--3FXVu";
export const context = "AppBlock-module--context--2cFzb";
export const title = "AppBlock-module--title--3WvnZ";
export const subtitle = "AppBlock-module--subtitle--ZEGfP";
export const content = "AppBlock-module--content--21ziV";
export const btnItems = "AppBlock-module--btn-items--dl0qD";
export const ctaButton = "AppBlock-module--cta-button--11JlA";
export const playStore = "AppBlock-module--play-store--2wHRi";
export const appStore = "AppBlock-module--app-store--1VtUe";