// extracted by mini-css-extract-plugin
export const root = "VideoBlock-module--root--2G3BW";
export const isMobile = "VideoBlock-module--is-mobile--TTHQ3";
export const leftSide = "VideoBlock-module--left-side--2MMu0";
export const title = "VideoBlock-module--title--3ANC_";
export const image = "VideoBlock-module--image--3RlGJ";
export const content = "VideoBlock-module--content--99kJQ";
export const contentContainer = "VideoBlock-module--content-container--1vNj1";
export const videoContainer = "VideoBlock-module--video-container--2XPgX";
export const quoteContainer = "VideoBlock-module--quote-container--cNR-B";
export const disclaimer = "VideoBlock-module--disclaimer--2xgwL";