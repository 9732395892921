// extracted by mini-css-extract-plugin
export const root = "WhatYouGetBlock-module--root--3AxaM";
export const title = "WhatYouGetBlock-module--title--1wu_o";
export const subtitle = "WhatYouGetBlock-module--subtitle--3amBM";
export const disclaimer = "WhatYouGetBlock-module--disclaimer--LXM8l";
export const listItem = "WhatYouGetBlock-module--list-item--3XHOu";
export const content = "WhatYouGetBlock-module--content--28-SP";
export const listItems = "WhatYouGetBlock-module--list-items--5UlX3";
export const BLUE = "WhatYouGetBlock-module--BLUE--GzmVr";
export const GREEN = "WhatYouGetBlock-module--GREEN--1jqZX";
export const left = "WhatYouGetBlock-module--left--ziRmW";
export const right = "WhatYouGetBlock-module--right--29m9M";
export const costBadge = "WhatYouGetBlock-module--cost-badge--2s1mS";
export const badgePrice = "WhatYouGetBlock-module--badge-price--HbrPS";
export const badgeRecurance = "WhatYouGetBlock-module--badge-recurance--17Qkj";
export const ctaButton = "WhatYouGetBlock-module--cta-button--WwPMi";
export const dppContainer = "WhatYouGetBlock-module--dpp-container--zCIze";
export const dppContact = "WhatYouGetBlock-module--dpp-contact--27X7d";
export const dppCtaBtn = "WhatYouGetBlock-module--dpp-cta-btn--3iq0v";
export const listItemsContainer = "WhatYouGetBlock-module--list-items-container--WIq15";
export const imageOnLeft = "WhatYouGetBlock-module--image-on-left--TiXZK";
export const titlesContainer = "WhatYouGetBlock-module--titles-container--188sz";