// extracted by mini-css-extract-plugin
export const root = "FocusBlock-module--root--1d6lm";
export const lightWhite = "FocusBlock-module--light-white--2gK5C";
export const babyBlue = "FocusBlock-module--baby-blue--1OHMw";
export const white = "FocusBlock-module--white--3jauN";
export const title = "FocusBlock-module--title--3VFde";
export const subtitle = "FocusBlock-module--subtitle--2lciJ";
export const content = "FocusBlock-module--content--30bUn";
export const ctaButton = "FocusBlock-module--cta-button--2ZTGv";
export const pointsContainer = "FocusBlock-module--points-container--3H8bP";
export const one = "FocusBlock-module--one--2G2rd";
export const two = "FocusBlock-module--two--3r4O_";
export const point = "FocusBlock-module--point--14DLU";
export const greenCircle = "FocusBlock-module--green-circle--1nZEP";
export const blueCircle = "FocusBlock-module--blue-circle--8xuMf";