// extracted by mini-css-extract-plugin
export const container = "CarouselBlock-module--container--22twG";
export const title = "CarouselBlock-module--title--2Glro";
export const happyfaceEmoji = "CarouselBlock-module--happyface-emoji--104hv";
export const content = "CarouselBlock-module--content--2u8gl";
export const items = "CarouselBlock-module--items--1vy8x";
export const slider = "CarouselBlock-module--slider--1IrDB";
export const flexContainer = "CarouselBlock-module--flex-container--2X6HP";
export const RIGHT = "CarouselBlock-module--RIGHT--9bX7l";
export const LEFT = "CarouselBlock-module--LEFT--1gk_e";
export const imageContainer = "CarouselBlock-module--image-container--37w9y";
export const cardDetails = "CarouselBlock-module--card-details--1uUWJ";
export const contactInfo = "CarouselBlock-module--contact-info--hvs6J";
export const disclaimer = "CarouselBlock-module--disclaimer--2j3wk";
export const btnItems = "CarouselBlock-module--btn-items--3Z27c";
export const ctaButton = "CarouselBlock-module--cta-button--13K1K";
export const appleStore = "CarouselBlock-module--apple-store--4c8eC";
export const googlePlay = "CarouselBlock-module--google-play--RB-K9";
export const ctaBtn = "CarouselBlock-module--cta-btn--NXmw0";
export const verticalDots = "CarouselBlock-module--vertical-dots--3KWNG";
export const slickActive = "CarouselBlock-module--slick-active--fZ9CX";
export const card = "CarouselBlock-module--card--2_OpS";