// extracted by mini-css-extract-plugin
export const desktopView = "HeroBlock-module--desktop-view--3OtFH";
export const mobileView = "HeroBlock-module--mobile-view--3LP_E";
export const heroImage = "HeroBlock-module--hero-image--1DtVw";
export const container = "HeroBlock-module--container--11VfN";
export const subContainer = "HeroBlock-module--sub-container--1Y9NA";
export const title = "HeroBlock-module--title--25Fzf";
export const MD = "HeroBlock-module--MD--qUIW2";
export const LG = "HeroBlock-module--LG--1mW0J";
export const content = "HeroBlock-module--content--HB4z2";
export const vertically = "HeroBlock-module--vertically--3BZke";
export const ctaBtn = "HeroBlock-module--cta-btn--3phow";
export const contactInfo = "HeroBlock-module--contact-info--1Lcwn";
export const horizontally = "HeroBlock-module--horizontally--1vquJ";
export const pipeline = "HeroBlock-module--pipeline--2BsAp";
export const mobileHeroContainer = "HeroBlock-module--mobile-hero-container--15sQX";
export const videoButton = "HeroBlock-module--videoButton--3Pb_m";
export const mobileHeroBanner = "HeroBlock-module--mobile-hero-banner--ian6S";