// extracted by mini-css-extract-plugin
export const root = "CTABlock-module--root--3WzFs";
export const left = "CTABlock-module--left--1PCw7";
export const content = "CTABlock-module--content--Agvhe";
export const right = "CTABlock-module--right--1Der5";
export const hasMobileCtaButton = "CTABlock-module--has-mobile-cta-button--3tp8N";
export const title = "CTABlock-module--title--3W89x";
export const subtitle = "CTABlock-module--subtitle--36oB2";
export const dppRoot = "CTABlock-module--dpp-root--1xU9X";
export const contactInformation = "CTABlock-module--contact-information--1ugrp";
export const smallerStyle = "CTABlock-module--smaller-style--QTtMZ";
export const contactInfoBlueText = "CTABlock-module--contact-info-blue-text--1M7BZ";