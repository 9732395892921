// extracted by mini-css-extract-plugin
export const root = "RatingBlock-module--root--27I97";
export const backgroundBlock = "RatingBlock-module--background-block--2WzUP";
export const title = "RatingBlock-module--title--q62SP";
export const appsContainer = "RatingBlock-module--apps-container--16Alq";
export const row = "RatingBlock-module--row--2DIVJ";
export const socialLink = "RatingBlock-module--social-link--1GcUZ";
export const appStoreContainer = "RatingBlock-module--app-store-container--2GuOT";
export const rating = "RatingBlock-module--rating--8flRI";
export const starBox = "RatingBlock-module--star-box--2Lx-7";
export const stars = "RatingBlock-module--stars--LysMG";
export const reviewCount = "RatingBlock-module--review-count--Ztn7Z";
export const logoBox = "RatingBlock-module--logo-box--3jcoQ";
export const appleLogo = "RatingBlock-module--apple-logo--HOCUF";
export const appStoreText = "RatingBlock-module--app-store-text--1lui1";
export const facebookContainer = "RatingBlock-module--facebook-container--o3onc";
export const thumbsUp = "RatingBlock-module--thumbs-up--1B8gf";
export const facebookLogo = "RatingBlock-module--facebook-logo--1vYGU";