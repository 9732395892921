// extracted by mini-css-extract-plugin
export const root = "ProgramBenefitsBlock-module--root--e0Oyt";
export const leftSide = "ProgramBenefitsBlock-module--left-side--1aazu";
export const title = "ProgramBenefitsBlock-module--title--R0ws2";
export const image = "ProgramBenefitsBlock-module--image--BHfs_";
export const content = "ProgramBenefitsBlock-module--content--39E9U";
export const rightSide = "ProgramBenefitsBlock-module--right-side--1VITb";
export const benefitContainer = "ProgramBenefitsBlock-module--benefit-container--3v5CU";
export const bulletType = "ProgramBenefitsBlock-module--bullet-type--3q1lT";
export const circle = "ProgramBenefitsBlock-module--circle--1RINa";
export const CHECK_MARK = "ProgramBenefitsBlock-module--CHECK_MARK--1tY7r";
export const stem = "ProgramBenefitsBlock-module--stem--3ppyd";
export const kick = "ProgramBenefitsBlock-module--kick--2zM96";
export const NUMBERING = "ProgramBenefitsBlock-module--NUMBERING--2vouN";
export const digit = "ProgramBenefitsBlock-module--digit--3xgFB";
export const contentDetails = "ProgramBenefitsBlock-module--content-details--YoD-d";
export const programName = "ProgramBenefitsBlock-module--program-name--MIWjQ";
export const smallText = "ProgramBenefitsBlock-module--small-text--1DnNZ";
export const sentenceFormat = "ProgramBenefitsBlock-module--sentence-format--3Htne";
export const bulletFormat = "ProgramBenefitsBlock-module--bullet-format--3nVbe";
export const ctaButton = "ProgramBenefitsBlock-module--cta-button--1ytrL";
export const disclaimer = "ProgramBenefitsBlock-module--disclaimer---byB5";