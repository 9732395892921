// extracted by mini-css-extract-plugin
export const root = "TestimonialsBlock-module--root--zHeFM";
export const title = "TestimonialsBlock-module--title--1Gf0j";
export const subtitle = "TestimonialsBlock-module--subtitle--3NIxn";
export const content = "TestimonialsBlock-module--content--2cLkQ";
export const testimonialCards = "TestimonialsBlock-module--testimonial-cards--1jjg6";
export const cardItem = "TestimonialsBlock-module--card-item--3IgVV";
export const memberImage = "TestimonialsBlock-module--member-image--3v-Wx";
export const testimonial = "TestimonialsBlock-module--testimonial--3XewA";
export const memberName = "TestimonialsBlock-module--member-name--3mVFN";
export const testimonialsDisclaimer = "TestimonialsBlock-module--testimonials-disclaimer--13uhu";