import React, {useEffect, useContext} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import MixpanelUtils from '@livongo/utils/mixpanel';
import NewRelicUtils from '@livongo/utilities/system/newrelic';
import {ChatProvider} from 'hook/ChatContext';
import {LanguageProvider, LanguageContext} from 'hook/LanguageContext';
import {GlobalProvider, GlobalContext} from 'hook/GlobalContext';
import useWindowSize from 'hook/useWindowSize';
import SetCookieScripts from 'hook/SetCookieScripts';
import {homePageLoad, setSuperAndPeopleProperties} from 'utils/mixpanel-utils';
import {removeNullFromProps, getCloudinaryImageUrl} from 'utils/utilities';
import BannerMessage from 'components/bannerMessage';
import Footer from 'components/footer';
import HeroBlock from 'components/heroBlock';
// Content Blocks
import AnimationBlock from 'components/animationBlock';
import AppBlock from 'components/appBlock';
import BreakingDownBarriersBlock from 'components/breakingDownBarriersBlock';
import CarouselBlock from 'components/carouselBlock';
import CTABlock from 'components/ctaBlock';
import DeviceBlock from 'components/deviceBlock';
import FAQBlock from 'components/faqBlock';
import FocusBlock from 'components/focusBlock';
import GuidelinesBlock from 'components/guidelinesBlock';
import PersonalizedSolutionBlock from 'components/personalizedSolutionBlock';
import ProgramBenefitsBlock from 'components/programBenefitsBlock';
import ProgramIconsBlock from 'components/programIconsBlock';
import ProgramOverviewBlock from 'components/programOverviewBlock';
import RatingBlock from 'components/ratingBlock';
import SupplyBlock from 'components/supplyBlock';
import SignupBlock from 'components/signupBlock';
import TestimonialsBlock from 'components/testimonialsBlock';
import VideoBlock from 'components/videoBlock';
import WhatYouGetBlock from 'components/whatYouGetBlock';
import navyIcon from './../../static/Livongo-navy-logo.png';
//import navyIcon from './../../static/Livongo_favicon.png';
import * as styles from './styles.module.scss';

const DEFAULT_LIVONGO_NUMBER = '1-800-945-4355';

const BLOCK_TYPES = Object.freeze({
    ANIMATION_BLOCK: 'ContentfulAnimationBlock',
    APP_BLOCK: 'ContentfulAppBlock',
    BREAKING_DOWN_BARRIERS_BLOCK: 'ContentfulBreakingDownBarriersBlock',
    CAROUSEL_BLOCK: 'ContentfulCarouselBlock',
    CTA_BLOCK: 'ContentfulCtaBlock',
    DEVICE_BLOCK: 'ContentfulDeviceBlock',
    FAQ_BLOCK: 'ContentfulFaqBlock',
    FOCUS_BLOCK: 'ContentfulFocusBlock',
    GUIDELINES_BLOCK: 'ContentfulGuidelinesBlock',
    PERSONALIZED_SOLUTION_BLOCK: 'ContentfulPersonalizedSolutionBlock',
    PROGRAM_BENEFITS_BLOCK: 'ContentfulProgramBenefitsBlock',
    PROGRAM_ICONS_BLOCK: 'ContentfulProgramIconsBlock',
    PROGRAM_OVERVIEW_BLOCK: 'ContentfulProgramOverviewBlock',
    RATING_BLOCK: 'ContentfulRatingBlock',
    SUPPLY_BLOCK: 'ContentfulSupplyBlock',
    SIGNUP_BLOCK: 'ContentfulSignupBlock',
    TESTIMONIALS_BLOCK: 'ContentfulTestimonialsBlock',
    VIDEO_BLOCK: 'ContentfulVideoBlock',
    WHAT_YOU_GET_BLOCK: 'ContentfulWhatYouGetBlock',
});

const AMERICAN_ENGLISH = 'en-US';

// TODO: these need to be replaced with environment variables
const SENTRY_DSN =
    'https://e375b75d784f4376b0edea675e206259@o501571.ingest.sentry.io/6139196';
let environment = 'PRODUCTION';
if (typeof window !== 'undefined') {
    if (window.location.host.includes('.local.livongo.com')) {
        environment = 'LOCAL';
    } else if (window.location.host.includes('.integration.livongo.com')) {
        environment = 'INTEGRATION';
    } else if (window.location.host.includes('.preprod.livongo.com')) {
        environment = 'PREPROD';
    } else if (window.location.host.includes('.tdoctest.com')) {
        environment = 'EOD';
    }
}

Sentry.init({
    dsn: SENTRY_DSN,
    release: process.env.BUILD_NUMBER,
    environment,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
});

// Update New Relic key values for this repository
const nrObject = {
    ACCOUNT_ID: process.env.ACCOUNT_ID,
    TRUST_KEY: process.env.TRUST_KEY,
    AGENT_ID: process.env.AGENT_ID,
    LICENSE_KEY: process.env.LICENSE_KEY,
    APPLICATION_ID: process.env.APPLICATION_ID,
};

// Initialize New Relic
if (typeof document !== 'undefined') {
    NewRelicUtils.init({document, nrObject});
}

const HomePages = props => {
    const {width: windowWidth} = useWindowSize(); // don't remove, captures page resize for banner
    const {language, updateTextStrings} = useContext(LanguageContext);
    const {setContactNumber, setCCID} = useContext(GlobalContext);

    // Maps all the languages avaliable from Contentful to it's index
    const languageIndexes = props.data.allContentfulHomePages.edges.reduce(
        (hash, edge, index) => {
            hash[edge.node.node_locale] = index;
            return hash;
        },
        {}
    );
    const languageProps =
        props.data.allContentfulHomePages.edges[languageIndexes[language] || 0]
            .node;

    const {
        title,
        plNumber,
        ccid,
        contactNumber = DEFAULT_LIVONGO_NUMBER,
        // translations,
        seoTags,
        commonTextStrings = {},
        headerBanner,
        heroBlock,
        contentBlocks,
        footer,
    } = removeNullFromProps(languageProps);

    // TODO: we need to add the common text string to all landing pages
    const {textStrings, memberCommunicationPolicy} = commonTextStrings;

    // Add tab title to page
    if (typeof document !== 'undefined' && title) {
        document.title = title || 'Livongo';
    }

    // If we have a language change, update the text
    useEffect(() => {
        if (textStrings) {
            updateTextStrings(textStrings);
        }
    }, [language]);

    // Initialize Mixpanel fire landing page event
    useEffect(() => {
        if (typeof window !== 'undefined') {
            MixpanelUtils.init(process.env.MIXPANEL_TOKEN);
            setSuperAndPeopleProperties();
            homePageLoad();
        }
        setContactNumber(contactNumber);
        if (ccid) {
            setCCID(ccid);
        }
    }, []);

    // Set Tatari, Meta, Bing, TikTok, and gtag cookie under specific URLs
    SetCookieScripts();

    return (
        <div className={styles.root}>
            {/* Populate title and metadata in the header for SEO purposes */}
            <ReactHelmet title={title} seoTags={seoTags} />
            <main role="main">
                {headerBanner && (
                    <BannerMessage
                        {...headerBanner}
                        displayExtoleOn={'header'}
                    />
                )}
                {heroBlock && <HeroBlock {...removeNullFromProps(heroBlock)} />}
                {contentBlocks &&
                    contentBlocks.map((contentBlock, i) => {
                        return displayContentBlock(
                            removeNullFromProps(contentBlock),
                            i
                        );
                    })}
            </main>
            <Footer
                plNumber={plNumber}
                memberCommunicationPolicy={memberCommunicationPolicy}
                {...footer}
            />
        </div>
    );
};

const HomePagesContextWrapper = props => {
    const {translations = []} = removeNullFromProps(
        props.data.allContentfulHomePages.edges[0].node
    );

    const availableLanguages = [AMERICAN_ENGLISH].concat(translations);

    return (
        <GlobalProvider>
            <LanguageProvider availableLanguages={availableLanguages}>
                <ChatProvider>
                    <HomePages {...props} />
                </ChatProvider>
            </LanguageProvider>
        </GlobalProvider>
    );
};

const ReactHelmet = ({title, seoTags = {}}) => {
    const {
        metaTags,
        links,
        googleOrganicResultsSchema,
        openGraphImage,
    } = seoTags;
    const openGraphImageUrl = getCloudinaryImageUrl(openGraphImage) || '';

    return (
        <Helmet>
            <link rel="icon" type="image/png" href={navyIcon}></link>
            {title ? <title>{title}</title> : 'Livongo'}
            {metaTags &&
                metaTags.map(metaTag => {
                    let metaData = {
                        [metaTag.nameAttibuteType ||
                        'name']: metaTag.nameAttibute,
                    };
                    if (metaTag.content) {
                        metaData['content'] = metaTag.content;
                    }
                    return <meta key={metaTag.nameAttibute} {...metaData} />;
                })}
            {links &&
                links.map(link => {
                    let metaData = {
                        [link.nameAttibuteType || 'rel']: link.nameAttibute,
                    };
                    if (link.content) {
                        metaData['href'] = link.content;
                    }
                    return <link key={link.nameAttibute} {...metaData} />;
                })}
            {googleOrganicResultsSchema?.internal?.content && (
                <script type="application/ld+json">
                    {seoTags?.googleOrganicResultsSchema.internal.content}
                </script>
            )}
            {openGraphImageUrl && (
                <meta property="og:image" content={openGraphImageUrl} />
            )}
        </Helmet>
    );
};

const displayContentBlock = (props, i) => {
    switch (props.__typename) {
        case BLOCK_TYPES.ANIMATION_BLOCK:
            return <AnimationBlock key={i} {...props} />;
        case BLOCK_TYPES.APP_BLOCK:
            return <AppBlock key={i} {...props} />;
        case BLOCK_TYPES.BREAKING_DOWN_BARRIERS_BLOCK:
            return <BreakingDownBarriersBlock key={i} {...props} />;
        case BLOCK_TYPES.CAROUSEL_BLOCK:
            return <CarouselBlock key={i} {...props} />;
        case BLOCK_TYPES.CTA_BLOCK:
            return <CTABlock key={i} {...props} />;
        case BLOCK_TYPES.DEVICE_BLOCK:
            return <DeviceBlock key={i} {...props} />;
        case BLOCK_TYPES.FAQ_BLOCK:
            return <FAQBlock key={i} {...props} />;
        case BLOCK_TYPES.FOCUS_BLOCK:
        case BLOCK_TYPES.FOCUS_BLOCK:
            return <FocusBlock key={i} {...props} />;
        case BLOCK_TYPES.GUIDELINES_BLOCK:
            return <GuidelinesBlock key={i} {...props} />;
        case BLOCK_TYPES.PERSONALIZED_SOLUTION_BLOCK:
            return <PersonalizedSolutionBlock key={i} {...props} />;
        case BLOCK_TYPES.PROGRAM_BENEFITS_BLOCK:
            return <ProgramBenefitsBlock key={i} {...props} />;
        case BLOCK_TYPES.PROGRAM_ICONS_BLOCK:
            return <ProgramIconsBlock key={i} {...props} />;
        case BLOCK_TYPES.PROGRAM_OVERVIEW_BLOCK:
            return <ProgramOverviewBlock key={i} {...props} />;
        case BLOCK_TYPES.RATING_BLOCK:
            return <RatingBlock key={i} {...props} />;
        case BLOCK_TYPES.SUPPLY_BLOCK:
            return <SupplyBlock key={i} {...props} />;
        case BLOCK_TYPES.SIGNUP_BLOCK:
            return <SignupBlock key={i} {...props} />;
        case BLOCK_TYPES.TESTIMONIALS_BLOCK:
            return <TestimonialsBlock key={i} {...props} />;
        case BLOCK_TYPES.VIDEO_BLOCK:
            return <VideoBlock key={i} {...props} />;
        case BLOCK_TYPES.WHAT_YOU_GET_BLOCK:
            return <WhatYouGetBlock key={i} {...props} />;
        default:
            return null;
    }
};

export default HomePagesContextWrapper;

export const query = graphql`
    fragment CloudinaryImage on ContentfulCloudinaryImage {
        name
        image {
            url
            height
            width
        }
    }

    fragment CloudinaryVideo on ContentfulCloudinaryVideo {
        name
        video {
            url
            height
            width
        }
    }

    fragment CTAButton on ContentfulCtaButton {
        buttonText
        backgroundColor
        size
        url
        buttonType
    }

    fragment BackgroundBlock on ContentfulBackgroundBlock {
        name
        textColorHex
        backgroundColorHex
        backgroundGradient
        backgroundImage {
            ...CloudinaryImage
        }
        orientation
        backgroundImageMaxWidth
        backgroundImagePadding
        desktopMinimumHeight
        desktopTopPadding
        desktopContainerTopPadding
        desktopContainerBottomPadding
        maxContainerWidth
        mobileBackgroundImage {
            ...CloudinaryImage
        }
        mobileBackgroundOrientation
        mobileBackgroundImageMaxWidth
        mobileBackgroundImagePadding
        alignContent
        customization
    }

    query($slug: String!) {
        allContentfulHomePages(filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    id
                    name
                    title
                    slug
                    ccid
                    plNumber
                    contactNumber
                    node_locale
                    translations
                    seoTags {
                        name
                        metaTags {
                            nameAttibute
                            nameAttibuteType
                            content
                        }
                        links {
                            nameAttibute
                            nameAttibuteType
                            content
                        }
                        googleOrganicResultsSchema {
                            internal {
                                content
                            }
                        }
                        openGraphImage {
                            ...CloudinaryImage
                        }
                    }
                    commonTextStrings {
                        textStrings {
                            key
                            text
                        }
                        memberCommunicationPolicy {
                            raw
                        }
                    }
                    headerBanner {
                        name
                        backgroundColorHex
                        textColorHex
                        content {
                            raw
                        }
                        ctaText
                        ctaLink
                        extendedContent {
                            extendedContent
                            childMarkdownRemark {
                                html
                            }
                        }
                        icon {
                            ...CloudinaryImage
                        }
                    }
                    heroBlock {
                        ...HeroBlock
                    }
                    contentBlocks {
                        ... on ContentfulAnimationBlock {
                            __typename
                            ...AnimationBlock
                        }
                        ... on ContentfulAppBlock {
                            __typename
                            ...AppBlock
                        }
                        ... on ContentfulBreakingDownBarriersBlock {
                            __typename
                            ...BreakingDownBarriersBlock
                        }
                        ... on ContentfulCarouselBlock {
                            __typename
                            ...CarouselBlock
                        }
                        ... on ContentfulCtaBlock {
                            __typename
                            ...CTABlock
                        }
                        ... on ContentfulDeviceBlock {
                            __typename
                            ...DeviceBlock
                        }
                        ... on ContentfulFaqBlock {
                            __typename
                            ...FAQBlock
                        }
                        ... on ContentfulFocusBlock {
                            __typename
                            ...FocusBlock
                        }
                        ... on ContentfulGuidelinesBlock {
                            __typename
                            ...GuidelinesBlock
                        }
                        ... on ContentfulPersonalizedSolutionBlock {
                            __typename
                            ...PersonalizedSolutionBlock
                        }
                        ... on ContentfulProgramBenefitsBlock {
                            __typename
                            ...ProgramBenefitsBlock
                        }
                        ... on ContentfulProgramOverviewBlock {
                            __typename
                            ...ProgramOverviewBlock
                        }
                        ... on ContentfulProgramIconsBlock {
                            __typename
                            ...ProgramIconsBlock
                        }
                        ... on ContentfulRatingBlock {
                            __typename
                            ...RatingBlock
                        }
                        ... on ContentfulSupplyBlock {
                            __typename
                            ...SupplyBlock
                        }
                        ... on ContentfulSignupBlock {
                            __typename
                            ...SignupBlock
                        }
                        ... on ContentfulTestimonialsBlock {
                            __typename
                            ...TestimonialsBlock
                        }
                        ... on ContentfulVideoBlock {
                            __typename
                            ...VideoBlock
                        }
                        ... on ContentfulWhatYouGetBlock {
                            __typename
                            ...WhatYouGetBlock
                        }
                    }
                    footer {
                        __typename
                        ...Footer
                    }
                }
            }
        }
    }
`;
