// extracted by mini-css-extract-plugin
export const title = "FAQBlock-module--title--2pgMi";
export const LEFT = "FAQBlock-module--LEFT--GrRsc";
export const CENTER = "FAQBlock-module--CENTER--yWkZH";
export const content = "FAQBlock-module--content--21jXp";
export const container = "FAQBlock-module--container--3Yr-A";
export const openChat = "FAQBlock-module--open-chat--2JFAC";
export const questionsSection = "FAQBlock-module--questions-section--3ZGBh";
export const section = "FAQBlock-module--section--2IfkA";
export const question = "FAQBlock-module--question--3nH76";
export const glypicon = "FAQBlock-module--glypicon--eFMUY";
export const plus = "FAQBlock-module--plus--olFO5";
export const minus = "FAQBlock-module--minus--AdGCq";
export const answer = "FAQBlock-module--answer--3xTBe";
export const show = "FAQBlock-module--show--3SGrS";
export const singleColumn = "FAQBlock-module--single-column--Lygh9";
export const questionSection = "FAQBlock-module--question-section--RdFDF";